import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Home from './components/Home';
import Observe from './components/Observe';
import MorningReflection from './components/MorningReflection';
import Memento from './components/Memento';
import NotFound from './components/NotFound';
import UserProfile from './components/UserProfile';
import About from './components/About';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Logout from './components/Logout';
import IntroMindfulness from './components/IntroMindfulness';
import IntroStoicReflection from './components/IntroStoicReflection';
import Intro6CEDReflection from './components/Intro6CEDReflection';
import TitleBar from './components/TitleBar'
import { useUser } from './components/UserContext';
import ProtectedRoute from './components/ProtectedRoute';
import PageTitle from './components/PageTitle'; 
import './App.css';

// init Google analytics
ReactGA.initialize('G-9DZ5H3RTGX');

function App() {
  const { currentUser } = useUser();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });
  }, []);

  return (
    <Router>
      <TitleBar />
      <div className="app">
        <nav id="sidebar-left">
          <p><a href="/">Home</a></p>
          <p>{ currentUser ? <a href="/profile">Profile</a> : <a href="/login">Login</a> }</p>
          <p>{ currentUser && <a href="/logout">Logout</a> }</p>
          <p>Practices</p>
          <p className="indented"><a href="/observe">Mindful Moments</a></p>
          <p className="indented"><a href="/morning">Purposeful Mornings</a></p>
          <p className="indented disabled"><a href="/evening" className="disabled">Hopeful Evenings</a> (Coming soon)</p>
          <p className="indented disabled"><a href="/life" className="disabled">Meaningful Life</a> (Coming soon)</p>
          <p>Resources</p>
          <p className="indented"><a href="/mindfulness">Mindfulness</a></p>
          <p className="indented"><a href="/stoicref">Stoic Reflection</a></p>
          <p className="indented"><a href="/6cedref">"6CED" Reflection</a></p>
          <p><a href="/about">About</a></p>
        </nav>
        <main id="main-content">
          <Routes>
            <Route path="/" exact element={
              <>
                <PageTitle />
                <Home />
              </>
            } />
            <Route path="/observe" element={
              <>
                <PageTitle title="Mindful Moment" />
                <Observe />
              </>
            } />
            <Route path="/morning" element={
              <>
                <PageTitle title="Purposeful Morning" />
                <MorningReflection />
              </>
            } />
            <Route path="/memento/:id" element={
              <>
                <PageTitle title="Memento" />
                <Memento />
              </>
            } />
            <Route path="/about" element={
              <>
                <PageTitle title="About" />
                <About />
              </>
            } />
            <Route path="/logout" element={
              <>
                <PageTitle title="Logout" />
                <Logout />
              </>
            } />
            <Route path="/profile" element={
              <>
                <PageTitle title="Profile" />
                <ProtectedRoute><UserProfile /></ProtectedRoute>
              </>
            } />
            <Route path="/signup" element={
              <>
                <PageTitle title="Signup" />
                <SignUp />
              </>
            } />
            <Route path="/login" element={
              <>
                <PageTitle title="Login" />
                <Login />
              </>
            } />
            <Route path="/mindfulness" element={
              <>
                <PageTitle title="Mindfulness - A Quick Intro" />
                <IntroMindfulness />
              </>
            } />
            <Route path="/stoicref" element={
              <>
                <PageTitle title="Stoic Reflection and Journaling - A Quick Intro" />
                <IntroStoicReflection />
              </>
            } />
            <Route path="/6cedref" element={
              <>
                <PageTitle title="6CED Reflection - A Quick Intro" />
                <Intro6CEDReflection />
              </>
            } />
            <Route element={
              <>
                <PageTitle title="Page Not Found" />
                <NotFound />
              </>
            } />
          </Routes>
        </main>
        <aside id="sidebar-right">
          <h2>Welcome to MindFeed</h2>
          <h3>Fun Intros to Science-based Practices for Creating Meaningful Lives</h3>
          <p></p>
          <ul className="items-list">
              <li>MindFeed helps you get started on the following practices that lead to meaningful lives: Mindful Moments, Purposeful Mornings, Hopeful Evenings and Meaningful Life.</li>
              <li>These practices have been derived from well known ancient practices as well as scientific facts and principles that form the basis of all life, the universe and everything.</li>
              <li>The ancient practices incoroporated in MindFeed include Mindfulness and Stoic Reflection.</li>
              <li>The scientific principles include the Free Energy Principle, Active Inference, and the ubiquitous and ever-present tendencies that can be observed in the universe, known as the "6CED" Tendencies.</li>
              <li>Click on the practice you want to learn and MindFeed will guide you through it.</li>
              <li>Each practice session takes only a few minutes, and at the end MindFeed uses Generative AI to automatically generate a fun, sharable memento of your session.</li>
              <li>Users who have signed in can edit and save the memento into their account, display it on their profile, and download a journal that documents their progression.</li>
              <li>Note that MindFeed only gives you quick intros to the practices. Eventually, you will need to learn the practice in more depth from an expert.</li>
          </ul>
          <p>Get started on an adeventure towards a more peaceful, productive and happy you!</p>
        </aside>
      </div>
    </Router>
  );
}

export default App;
