import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function About() {
  const navigate = useNavigate();

  const handleHome = () => {
      navigate('/');
  };

  return (
    <div className="page-content">
      <h2>About MindFeed</h2>
      <h3>Fun Intros to Science-based Practices for Creating Meaningful Lives</h3>
      <ul className="items-list">
          <li>MindFeed helps you get started on the following practices that lead to meaningful lives: Mindful Moments, Purposeful Mornings, Hopeful Evenings and Meaningful Life.</li>
          <li>These practices have been derived from well known ancient practices as well as scientific facts and principles that form the basis of all life, the universe and everything.</li>
          <li>The ancient practices incoroporated in MindFeed include Mindfulness and Stoic Reflection.</li>
          <li>The scientific principles include the Free Energy Principle, Active Inference, and the ubiquitous and ever-present tendencies that can be observed in the universe, known as the "6CED" Tendencies.</li>
          <li>Click on the practice you want to learn and MindFeed will guide you through it.</li>
          <li>Each practice session takes only a few minutes, and at the end MindFeed uses Generative AI to automatically generate a fun, sharable memento of your session.</li>
          <li>Users who have signed in can edit and save the memento into their account, display it on their profile, and download a journal that documents their progression.</li>
          <li>Note that MindFeed only gives you quick intros to the practices. Eventually, you will need to learn the practice in more depth from an expert.</li>
      </ul>
      <h3>Background</h3>
      <p>MindFeed is being developed by <a href="https://bhalerao.org" target="_blank" rel="noreferrer">Vin Bhalerao</a>, as a part of the <a href="https://lifevisor.ai" target="_blank" rel="noreferrer">LifeVisor.AI project</a>.</p>
      <p>LifeVisor is a long term project that aims to make life more meaningful, happier and productive by bringing it in alignment with the universe's inherent properties and tendencies.</p>
      <p>For more details about the underlying science and philosophy behind this approach towards finding meaning, purpose and hope in your life, please take a look at the book, <a href="https://meaning.lifevisor.ai" target="_blank" rel="noreferrer">An Engineer's Search for Meaning</a>.</p>
      <p>Feedback? Suggestions? Please use the form on <a href="https://meaning.lifevisor.ai/#contact" target="_blank" rel="noreferrer">this page</a>.</p>
      <p>Click on Home to get started on an adeventure towards a more peaceful, productive and happy you!</p>
    
      <div className="dialog-button-bar">
          <button className="app-button" onClick={handleHome}>Home</button>
      </div>
    </div>
  );
}

export default About;
