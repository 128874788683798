import React, { useState, useEffect } from 'react';
import { getIdToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { MementoType, MementoSubtype, isGenericMemento } from './Utils.js';
import Modal from './Modal';
import '../App.css';

function Observe() {
  const { currentUser } = useUser();
  const [view, setView] = useState('intro');
  const [items, setItems] = useState({ item1: '', item2: '', item3: '' });
  const [subtype, setSubtype] = useState(getRandomMementoSubtype(currentUser === null));
  const [errorMessage, setErrorMessage] = useState('');
  const [waitingMessage, setWaitingMessage] = useState('');

  const handlePromptItemChange = (e) => {
    setItems({ ...items, [e.target.name]: e.target.value });
  };

  const handleSubtypeChange = (e) => {
    if (e.target.value === "generic")
      setSubtype(getRandomMementoSubtype(true));
    else
      setSubtype(Number(e.target.value));
  };

  function getRandomMementoSubtype(isGeneric) {
    const values = isGeneric ? [MementoSubtype.SHORTPOEM, MementoSubtype.QUOTE] :
                               [MementoSubtype.SCIFI, MementoSubtype.ROMANCE, MementoSubtype.POEM, MementoSubtype.MONOLOGUE];
      
    const randomIndex = Math.floor(Math.random() * values.length);
    return values[randomIndex];
  }  
  
  const updateView = (e, index, view) => {
    e.preventDefault();

    if (index > 0)
      setItems({ ...items, [`item${index}`]: items[`item${index}`].trim() });

    setView(view);
    window.scrollTo(0, 0);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = { type: MementoType.MINDFULNESS, subtype: subtype, items: Object.values(items) };
      let response;

      setWaitingMessage('Generating your memento. This may take up to a minute...');

      if (currentUser) {
        const idToken = await getIdToken(currentUser);

        response = await fetch('/api_prompt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify(payload),
        });  
      } else {
        response = await fetch('/api_prompt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });  
      }

      const responseData = await response.json();

      if (response.ok) {
        setWaitingMessage('');
        window.location.href = '/memento/' + responseData.id;
      } else {
        throw new Error('Failed to submit prompt items');
      }
    } catch (error) {
      setWaitingMessage('');
      console.log('Error: ', error);
    }
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  // handle enter key
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        switch (view) {
          case 'intro': updateView(event, 0, 'surroundings'); break;
          case 'surroundings': updateView(event, 1, 'body'); break;
          case 'body': updateView(event, 2, 'mind'); break;
          case 'mind': updateView(event, 3, 'submit'); break;
          case 'submit': handleSubmit(event); break;
          default: break;
        }
      } 
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [view, items]);

  return (
    <div className="page-content">
      <form onSubmit={handleSubmit}>
        {view === 'intro' && (
          <div>
            <h3>1/5. Taking the first baby steps towards Mindfulness:</h3>
            <ol className="items-list">
              <li>Mindfulness is the ancient practice of focusing your full attention on the present moment. It involves simply observing and labeling things happening around you, as well as inside your body and your mind, clearly and calmly, without making any judgment about them.</li>
              <li>Practicing mindfulness in everyday activities has been proven to improve focus, productivity and peace of mind.</li>
              <li>Simply observing and labeling things occurring in the present moment allows you to see things more clearly, think more clearly about them, and act more effectively on them.</li>
              <li>Becoming really good at mindfulness is a lot harder than it sounds and takes a long time. But every little step can produce tangible benefits.</li>
              <li>So here is a quick exercise just to introduce the basic idea to you. It should take only a couple of minutes.</li>
              <li>And, to make it fun, you will get a little sharable memento at the end that might even inspire you to become creative!</li>
              <li>Click on Next when you are ready to start:</li>
            </ol>
            <div className="dialog-button-bar">
              <button  className="app-button" onClick={handleBackClick}>Back</button>
              <button className="app-button" onClick={() => setView('surroundings')}>Next</button>
            </div>
          </div>
        )}
        {view === 'surroundings' && (
          <div>
            <h3>2/5. Mindfully focusing on your surroundings:</h3>
            <ol className="items-list">
              <li>Settle down comfortably wherever you are. Take a deep breath and exhale slowly.</li>
              <li>Look around yourself and notice something that catches your attention. It could be anything you see or hear or smell or touch (e.g. "pen", "chair", "carpet" etc.)</li>
              <li>Try to pay your full attention to that object. Notice some of its properties, such as its color or shape or texture or the way it is changing etc.</li>
              <li>If your mind wanders, that's ok. Gently and playfully bring your focus back to the object. Allow it to fill your attention as much as you can, at least for a few seconds.</li>
              <li>Before moving on to the next step, enter a short description of the object in the input field below (e.g. "red ballpoint pen" or "creaking wooden chair" or "potted plant with red flowers").</li>
            </ol>
            <div>
              <label htmlFor="item1"><b>Short description of the object you focused on:</b> </label>
              <br/>
              <input
                type="text"
                className="form-control mb-3"
                placeholder={`e.g. creaking wooden chair`}
                maxLength="50"
                name="item1"
                id="item1"
                value={items["item1"]}
                onChange={handlePromptItemChange}
              />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'intro')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 1, 'body')}>Next</button>
            </div>
          </div>
        )}
        {view === 'body' && (
          <div className="page-content">
            <h3>3/5. Now it is time to focus on your body:</h3>
            <ol className="items-list">
              <li>Gently close your eyes and turn your focus inwards, towards your own body.</li>
              <li>You will start to noice various physical sensations occurring in your body, such as your breath coming in and going, maybe an itch somewhere on your skin, or tenseness in your muscles etc.</li>
              <li>Once again, choose whatever catches your attention and pay as careful attention to it as you can, at least for a few seconds.</li>
              <li>And again, if your mind wanders, just gently and playfully bring it back to the sensation. Allow it to fill your attention as much as you can, without trying to judge it as good or bad.</li>
              <li>Before moving on to the next step, enter a short description of that sensation in the input field below (e.g. "aching feet", "growling stomach", "sweaty brow"):</li>
            </ol>
            <div>
              <label htmlFor="item2"><b>Short description of your current bodily sensation:</b> </label>
              <br/>
              <input
                type="text"
                className="form-control mb-2"
                placeholder={`e.g. aching feet`}
                maxLength="50"
                name="item2"
                id="item2"
                value={items["item2"]}
                onChange={handlePromptItemChange}
              />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'surroundings')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 2, 'mind')}>Next</button>
            </div>
          </div>
        )}
        {view === 'mind' && (
          <div className="page-content">
            <h3>4/5. Going deeper, focusing on your mind:</h3>
            <ol className="items-list">
              <li>Now it is time to pay attention to what is going on inside your mind itself.</li>
              <li>Notice any thought or emotion passing through your mind. Simply observe and label the thought or emotion as it is.</li>
              <li>Try to stay with it for a short time. Try to look at your own mind playfully, as if it is a child. It is important to remember not to make any judgements about it.</li>
              <li>This is probably the hardest part of the practice, but even a minute amount of regular progress towards it will compound over time.</li>
              <li>Before moving to the next step, enter a short description of that thought or feeling in the input field below (e.g. "curious about what happens next" or "tired and sleepy" or "the party this weekend"):</li>
            </ol>
            <div>
              <label htmlFor="item3"><b>Short description of the thought or feeling passing through your mind:</b> </label>
              <br/>
              <input
                type="text"
                className="form-control mb-2"
                placeholder={`e.g. curious about what happens next`}
                maxLength="50"
                name="item3"
                id="item3"
                value={items["item3"]}
                onChange={handlePromptItemChange}
              />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'body')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 3, 'submit')}>Next</button>
            </div>
          </div>
        )}
        {view === 'submit' && (
          <div className="page-content">
            <h3>5/5. That's it! Now it's time for your prize:</h3>
            <ol className="items-list">
              <li>All the descriptions you have entered during this practice will now be fed to the MindFeed AI and it will create a fun 'memento' based on them.</li>
              <li><a href="/login">Signed in</a> users can edit the memento to if they choose to and save it to their profile.</li>
              <li>Please note that what really matters is doing the practice regularly. The memento is only meant as a fun sharable souvenir. Return to MindFeed on a daily basis and you will start to see its benefits over time.</li>
              <li>Also note that MindFeed is intended just as a quick introduction to these practices. Eventually you may want to take lessons from a real expert.</li>
            </ol>
            <div>
              <b>Choose the type of memento and submit:</b><br />
              <input type="radio" className="indented" id="generic" name="generic" value="generic" checked={isGenericMemento(subtype)} onChange={handleSubtypeChange} />
              <label htmlFor="generic">Generic memento</label><br /><br />
              <span className='indented'>(Personalized memento{ !currentUser && <span> - <a href="/login">Sign in</a> to enable</span> }):</span><br/>
              <input type="radio" id="poem" name="subtype" value={MementoSubtype.POEM} checked={subtype === MementoSubtype.POEM} onChange={handleSubtypeChange} disabled={currentUser==null} />
              <label htmlFor="poem" className={ currentUser ? "" : "disabled" }>Poem</label><br />
              <input type="radio" id="monologue" name="subtype" value={MementoSubtype.MONOLOGUE} checked={subtype === MementoSubtype.MONOLOGUE} onChange={handleSubtypeChange} disabled={currentUser==null} />
              <label htmlFor="monologue" className={ currentUser ? "" : "disabled" }>Monologue</label><br />
              <input type="radio" id="scifi" name="subtype" value={MementoSubtype.SCIFI} checked={subtype === MementoSubtype.SCIFI} onChange={handleSubtypeChange} disabled={currentUser==null} />
              <label htmlFor="scifi" className={ currentUser ? "" : "disabled" }>Sci-Fi</label><br />              
              <input type="radio" id="romance" name="subtype" value={MementoSubtype.ROMANCE} checked={subtype === MementoSubtype.ROMANCE} onChange={handleSubtypeChange} disabled={currentUser==null} />
              <label htmlFor="romance" className={ currentUser ? "" : "disabled" }>Romance</label><br />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'mind')}>Back</button>
              <button type="submit" className="app-button">Submit</button>
            </div>
          </div>
        )}
      </form>
      <Modal show={waitingMessage} title="Please Wait" onClose={() => setWaitingMessage('')}>
        <p>{waitingMessage}</p>
      </Modal>
      <Modal show={errorMessage} title="Error" onClose={() => setErrorMessage('')}>
        <p>{errorMessage}</p>
      </Modal>
    </div>
  );
}

export default Observe;
