import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = ({ title }) => {
  const location = useLocation();

  useEffect(() => {
    const baseTitle = "MindFeed: Science-based Practices for Creating Meaningful Lives";
    document.title = title ? (title + " | " + baseTitle) : baseTitle;
  }, [location, title]);

  return null;
};

export default PageTitle;