// This list must match the one in app.py
export const MementoType = Object.freeze({ 
  MINDFULNESS: 1,
  MORNING: 2,
  EVENING: 3,
  LIFE: 4
}); 

// This list must match the one in app.py
export const MementoSubtype = Object.freeze({
    SCIFI: 1,
    ROMANCE: 2,
    POEM: 3,
    MONOLOGUE: 4,
    SHORTPOEM: 5,
    QUOTE: 6
});

// This list must match the one in app.py
// These can be combined using OR
export const TendencyType = Object.freeze({
  COHERENCE: 0x01,
  COMPLEXITY: 0x02,
  CONTINUITY: 0x04,
  CURIOSITY: 0x08,
  CREATIVITY: 0x10,
  CONSCIOUSNESS: 0x20,
  EVOLUTION: 0x40,
  DIVERSITY: 0x80
});

export const writingFromMementoSubtype = (subtype) => {
  switch (subtype) {
      case MementoSubtype.SCIFI: return "Sci-Fi story";
      case MementoSubtype.ROMANCE: return "romantic story";
      case MementoSubtype.POEM: return "poem";
      case MementoSubtype.MONOLOGUE: return "monologue";
      case MementoSubtype.SHORTPOEM: return "poem";
      case MementoSubtype.QUOTE: return "fortune cookie quote";
      default: return "Error";
  }
};

export const adjectiveFromMementoSubtype = (subtype) => {
  switch (subtype) {
      case MementoSubtype.SCIFI: return "Futuristic";
      case MementoSubtype.ROMANCE: return "Romantic";
      case MementoSubtype.POEM: return "Poetic";
      case MementoSubtype.MONOLOGUE: return "Reflective";
      case MementoSubtype.SHORTPOEM: return "Poetic";
      case MementoSubtype.QUOTE: return "Quotable";
      default: return "Error";
  }
};

export const practiceFromMementoType = (type) => {
  switch (type) {
      case MementoType.MINDFULNESS: return 'Mindful Moment';
      case MementoType.MORNING: return 'Purposeful Morning';
      case MementoType.EVENING: return 'Hopeful Evening';
      case MementoType.LIFE: return 'Meaningful Life';
      default: return "Error";
  }
};

export const isGenericMemento = (subtype) => {
  return subtype === MementoSubtype.SHORTPOEM || subtype === MementoSubtype.QUOTE;
}

export const fontColorFromMemento = (memento) => {
  let fontColor = '#000000';

  switch (memento.subtype) {
    case MementoSubtype.SCIFI:
        fontColor = '#000080';
        break;

    case MementoSubtype.POEM:
        fontColor = '#654321';
        break;

    case MementoSubtype.ROMANCE:
        fontColor = '#C4146C';
        break;

    case MementoSubtype.MONOLOGUE:
        fontColor = '#36454F';
        break;

    default:
      fontColor = '#000000';
      break;
  }

  return fontColor;
};

export const backColorFromMemento = (memento) => {
  let backColor = '#FFFFFF';

  switch (memento.subtype) {
    case MementoSubtype.SCIFI:
        backColor = '#E4EFF0';
        break;

    case MementoSubtype.POEM:
        backColor = '#FFFDD0';
        break;

    case MementoSubtype.ROMANCE:
        backColor = '#FFFBD7';
        break;

    case MementoSubtype.MONOLOGUE:
        backColor = '#F5F5F5';
        break;

    default:
      backColor = '#FFFFFF';
      break;
  }

  return backColor;
};

// Memento id encoding
require('base64url');
const SECRET_OFFSET = 247133; // Arbitrary number to modify the index, must match the value in app.py

export const encodeMementoId = (id) => {
  // Add the secret offset to the ID
  const modifiedId = id + SECRET_OFFSET;

  // Convert the modified ID to a string and then to a buffer
  const modifiedIdStr = modifiedId.toString();

  // Encode the string using base64 URL-safe encoding
  const encoded = btoa(modifiedIdStr)
    .replace(/\+/g, '-')  // Replace + with -
    .replace(/\//g, '_')  // Replace / with _
    .replace(/=+$/, '');  // Remove trailing =

  return encoded;
}
