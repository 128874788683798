import React, { useState, useEffect } from 'react';
import { getIdToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { MementoType, MementoSubtype, TendencyType, isGenericMemento } from './Utils.js';
import Modal from './Modal';
import '../App.css';

function MorningReflection() {
  const { currentUser } = useUser();
  const [view, setView] = useState('intro');
  const [items, setItems] = useState({ item1: '', item2: '', item3: '' });
  const [tendenciesList, setTendenciesList] = useState({ tendencies1: 0, tendencies2: 0, tendencies3: 0 });
  const [subtype, setSubtype] = useState(getRandomMementoSubtype(currentUser === null));
  const [errorMessage, setErrorMessage] = useState('');
  const [waitingMessage, setWaitingMessage] = useState('');

  const handlePromptItemChange = (e) => {
    setItems({ ...items, [e.target.name]: e.target.value });
  };

  const handleSubtypeChange = (e) => {
    if (e.target.value === "generic")
      setSubtype(getRandomMementoSubtype(true));
    else
      setSubtype(Number(e.target.value));
  };

  function getRandomMementoSubtype(isGeneric) {
    const values = isGeneric ? [MementoSubtype.SHORTPOEM, MementoSubtype.QUOTE] :
                               [MementoSubtype.SCIFI, MementoSubtype.ROMANCE, MementoSubtype.POEM, MementoSubtype.MONOLOGUE];
      
    const randomIndex = Math.floor(Math.random() * values.length);
    return values[randomIndex];
  }  

  const handleTendenciesChange = (e) => {
    let tendencies = tendenciesList[e.target.name];
    tendencies &= ~e.target.value;
    if (e.target.checked)
      tendencies |= e.target.value;
    setTendenciesList({ ...tendenciesList, [e.target.name]: tendencies });
  };

  const getTendencies = (checkboxSet) => {
    let tendencies = 0;
    let tendenciesArray = document.querySelectorAll('input[name="' + checkboxSet + '"]:checked');

    Array.prototype.forEach.call(tendenciesArray, function(el) {
        tendencies = tendencies | el.value;
    });

    return tendencies;
  };

  const updateView = (e, index, view) => {
    e.preventDefault();

    if (index > 0) {
      setItems({ ...items, [`item${index}`]: items[`item${index}`].trim() });
      setTendenciesList({ ...tendenciesList, [`tendencies${index}`]: getTendencies(`tendencies${index}`) });
    }

    setView(view);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = { type: MementoType.MORNING, subtype: subtype, items: Object.values(items), tendencies_list: Object.values(tendenciesList) };
      let response;

      setWaitingMessage('Generating your memento. This may take up to a minute...');

      if (currentUser) {
        const idToken = await getIdToken(currentUser);

        response = await fetch('/api_prompt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify(payload),
        });  
      } else {
        response = await fetch('/api_prompt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });  
      }

      const responseData = await response.json();

      if (response.ok) {
        setWaitingMessage('');

        window.location.href = '/memento/' + responseData.id;
      } else {
        throw new Error('Failed to submit prompt items');
      }
    } catch (error) {
      setWaitingMessage('');
      console.log('Error: ', error);
    }
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  // handle enter key
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        switch (view) {
          case 'intro': updateView(event, 0, 'identify'); break;
          case 'identify': updateView(event, 0, 'act1'); break;
          case 'act1': updateView(event, 1, 'act2'); break;
          case 'act2': updateView(event, 2, 'act3'); break;
          case 'act3': updateView(event, 3, 'submit'); break;
          case 'submit': handleSubmit(event); break;
          default: break;
        }
      } 
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [view, items]);

  return (
    <div className="page-content">
      <form onSubmit={handleSubmit}>
        {view === 'intro' && (
          <div>
            <h3>1/6. Introduction to the "Purposeful Mornings" Practice:</h3>
            <ol className="items-list">
              <li>This practice is based on the Stoic practice of Morning Reflection, with the addition of the 6CED principles outlined in my book, 
                <a href="https://meaning.lifevisor.ai" target="_blank" rel="noreferrer">"An Engineer's Search for Meaning"</a>. 
                These principles provide a scientific way to make our lives meaningful, purposeful and hopeful by aligning ourselves with the inherent tendencies of the universe.</li>
              <li>The practice involves sitting quietly for just a few moments every morning and previewing your goals or planned activities for the day, while ensuring that the goals or activities are in alignment with the 6CED principles.</li>
              <li>Doing this practice everyday improves productivity, focus and peace of mind, while also contributing to the sense of meaning, purpose and hope in our lives.</li>
              <li>You will now get stepwise guidance to perform this practice. And, to make it fun, you will get a sharable memento at the end that might even inspire you to become creative!</li>
              <li>Click on Next when you are ready to start:</li>
            </ol>
            <div className="dialog-button-bar">
              <button  className="app-button" onClick={handleBackClick}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 0, 'identify')}>Next</button>
            </div>
          </div>
        )}
        {view === 'identify' && (
          <div>
            <h3>2/6. Identify Your Activities for the Day:</h3>
            <ol className="items-list">
              <li>Start by asking yourself what you need to accomplish today. These may be personal activities, work or school activities, or family or community activities.</li>
              <li>Visualize yourself actually performing the activities and overcoming the challenges that may come up. Doing so may lead to insights that require modifying the activities.</li>
              <li>In addition, you also need to identify which of the 6CED principles the activities are aligned with.</li>
              <li>Here is the list of the 6CED principles for your reference: Achieving <b>Coherence or Comprehensibility, Complexity, Continuity of Existence or Identity, 
              Curiosity, Creativity, Consciousness Enrichment, Evolution and Diversity</b></li>
              <li>In the beginning, it may be hard to achieve this alignment for at least some of your activites, but over time, as you see the benefits, they should become better aligned.</li>
              <li>In the next 3 steps, we will enter up to 3 such activities you have identified for today. Click on Next when you are ready to enter the first activity:</li>
            </ol>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'intro')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 0, 'act1')}>Next</button>
            </div>
          </div>
        )}
        {view === 'act1' && (
          <div className="page-content">
            <h3>3/6. Activity #1:</h3>
            <div>
              <label htmlFor="item1"><b>Short description of activity #1:</b> </label>
              <br/>
              <input
                type="text"
                className="form-control mb-2"
                placeholder={`e.g. Complete a 30-minute HIIT workout`}
                maxLength="50"
                name="item1"
                id="item1"
                value={items["item1"]}
                onChange={handlePromptItemChange}
              />
            </div>
            <div>
                <b>Select one or more 6CED principles the activity may be aligned with:</b><br />
                <input type="checkbox" name="tendencies1" id="coherence1" value={TendencyType.COHERENCE} checked={tendenciesList["tendencies1"] & TendencyType.COHERENCE} onChange={handleTendenciesChange} />
                <label htmlFor="coherence1"><b>Coherence/Comprehensibility</b> (Does it make your life or the world more coherent or comprehensible? E.g. learning something new.)</label><br />
                <input type="checkbox" name="tendencies1" id="complexity1" value={TendencyType.COMPLEXITY} checked={tendenciesList["tendencies1"] & TendencyType.COMPLEXITY} onChange={handleTendenciesChange} />
                <label htmlFor="complexity1"><b>Complexity</b> (Does it involve building or maintaining complex things? E.g. creating a complex dish.)</label><br />
                <input type="checkbox" name="tendencies1" id="continuity1" value={TendencyType.CONTINUITY} checked={tendenciesList["tendencies1"] & TendencyType.CONTINUITY} onChange={handleTendenciesChange} />
                <label htmlFor="continuity1"><b>Continuity</b> (Does it involve building or maintaining things that last long? E.g. improving your health.)</label><br />
                <input type="checkbox" name="tendencies1" id="curiosity1" value={TendencyType.CURIOSITY} checked={tendenciesList["tendencies1"] & TendencyType.CURIOSITY} onChange={handleTendenciesChange} />
                <label htmlFor="curiosity1"><b>Curiosity</b> (Is it driven by curiosity? E.g. travel or meeting new people or exploring new ideas.)</label><br />
                <input type="checkbox" name="tendencies1" id="creativity1" value={TendencyType.CREATIVITY} checked={tendenciesList["tendencies1"] & TendencyType.CREATIVITY} onChange={handleTendenciesChange} />
                <label htmlFor="creativity1"><b>Creativity</b> (Will it create something new? E.g. a new product or service or idea etc.)</label><br />
                <input type="checkbox" name="tendencies1" id="consciousness1" value={TendencyType.CONSCIOUSNESS} checked={tendenciesList["tendencies1"] & TendencyType.CONSCIOUSNESS} onChange={handleTendenciesChange} />
                <label htmlFor="consciousness1"><b>Consciousness</b> (Does it involve a consciousness-enriching experience? E.g. creating or experiecing art, music etc.)</label><br />
                <input type="checkbox" name="tendencies1" id="evolution1" value={TendencyType.EVOLUTION} checked={tendenciesList["tendencies1"] & TendencyType.EVOLUTION} onChange={handleTendenciesChange} />
                <label htmlFor="evolution1"><b>Evolution</b> (Does it have the potential to evolve into something better? E.g. starting a new project or relationship.)</label><br />
                <input type="checkbox" name="tendencies1" id="diversity1" value={TendencyType.DIVERSITY} checked={tendenciesList["tendencies1"] & TendencyType.DIVERSITY} onChange={handleTendenciesChange} />
                <label htmlFor="diversity1"><b>Diversity</b> (Will it lead to increased diversity in the world or your own thinking? E.g. doing or experiencing something novel.)</label><br />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'identify')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 1, 'act2')}>Next</button>
            </div>
          </div>
        )}
        {view === 'act2' && (
          <div className="page-content">
            <h3>4/6. Activity #2:</h3>
            <div>
              <label htmlFor="item2"><b>Short description of activity #2:</b> </label>
              <br/>
              <input
                type="text"
                className="form-control mb-2"
                placeholder={`e.g. Finalize and submit the Acme proposal`}
                maxLength="50"
                name="item2"
                id="item2"
                value={items["item2"]}
                onChange={handlePromptItemChange}
              />
            </div>
            <div>
                <b>Select one or more 6CED Tendencies the activity may be aligned with:</b><br />
                <input type="checkbox" name="tendencies2" id="coherence2" value={TendencyType.COHERENCE} checked={tendenciesList["tendencies2"] & TendencyType.COHERENCE} onChange={handleTendenciesChange} />
                <label htmlFor="coherence2"><b>Coherence/Comprehensibility</b> (Does the activity make your life or the world more coherent or comprehensible? E.g. learning or teaching something new.)</label><br />
                <input type="checkbox" name="tendencies2" id="complexity2" value={TendencyType.COMPLEXITY} checked={tendenciesList["tendencies2"] & TendencyType.COMPLEXITY} onChange={handleTendenciesChange} />
                <label htmlFor="complexity2"><b>Complexity</b> (Does the activity involve building or maintaining complex things? E.g. creating a complex dish from simple ingredients.)</label><br />
                <input type="checkbox" name="tendencies2" id="continuity2" value={TendencyType.CONTINUITY} checked={tendenciesList["tendencies2"] & TendencyType.CONTINUITY} onChange={handleTendenciesChange} />
                <label htmlFor="continuity2"><b>Continuity</b> (Does the activity involve building or maintaining things that last long? E.g. improving your health.)</label><br />
                <input type="checkbox" name="tendencies2" id="curiosity2" value={TendencyType.CURIOSITY} checked={tendenciesList["tendencies2"] & TendencyType.CURIOSITY} onChange={handleTendenciesChange} />
                <label htmlFor="curiosity2"><b>Curiosity</b> (Is the activity driven by curiosity? E.g. travel or meeting new people or exploring new ideas.)</label><br />
                <input type="checkbox" name="tendencies2" id="creativity2" value={TendencyType.CREATIVITY} checked={tendenciesList["tendencies2"] & TendencyType.CREATIVITY} onChange={handleTendenciesChange} />
                <label htmlFor="creativity2"><b>Creativity</b> (Will the activity create something new? E.g. a new product or service or idea etc.)</label><br />
                <input type="checkbox" name="tendencies2" id="consciousness2" value={TendencyType.CONSCIOUSNESS} checked={tendenciesList["tendencies2"] & TendencyType.CONSCIOUSNESS} onChange={handleTendenciesChange} />
                <label htmlFor="consciousness2"><b>Consciousness</b> (Does the activity involve a consciousness-enriching experience? E.g. playing great music or writing something meaningful.)</label><br />
                <input type="checkbox" name="tendencies2" id="evolution2" value={TendencyType.EVOLUTION} checked={tendenciesList["tendencies2"] & TendencyType.EVOLUTION} onChange={handleTendenciesChange} />
                <label htmlFor="evolution2"><b>Evolution</b> (Does the activity have the potential to evolve into something better? E.g. starting a new project or relationship.)</label><br />
                <input type="checkbox" name="tendencies2" id="diversity2" value={TendencyType.DIVERSITY} checked={tendenciesList["tendencies2"] & TendencyType.DIVERSITY} onChange={handleTendenciesChange} />
                <label htmlFor="diversity2"><b>Diversity</b> (Will the activity lead to increased diversity in the world or your own thinking? E.g. supporting something new and generally overlooked.)</label><br />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'act1')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 2, 'act3')}>Next</button>
            </div>
          </div>
        )}
        {view === 'act3' && (
          <div className="page-content">
            <h3>5/6. Activity #3:</h3>
            <div>
              <label htmlFor="item3"><b>Short description of activity #3:</b> </label>
              <br/>
              <input
                type="text"
                className="form-control mb-2"
                placeholder={`e.g. Learn a new painting technique`}
                maxLength="50"
                name="item3"
                id="item3"
                value={items["item3"]}
                onChange={handlePromptItemChange}
              />
            </div>
            <div>
                <b>Select one or more 6CED Tendencies the activity may be aligned with:</b><br />
                <input type="checkbox" name="tendencies3" id="coherence3" value={TendencyType.COHERENCE} checked={tendenciesList["tendencies3"] & TendencyType.COHERENCE} onChange={handleTendenciesChange} />
                <label htmlFor="coherence3"><b>Coherence/Comprehensibility</b> (Does the activity make your life or the world more coherent or comprehensible? E.g. learning or teaching something new.)</label><br />
                <input type="checkbox" name="tendencies3" id="complexity3" value={TendencyType.COMPLEXITY} checked={tendenciesList["tendencies3"] & TendencyType.COMPLEXITY} onChange={handleTendenciesChange} />
                <label htmlFor="complexity3"><b>Complexity</b> (Does the activity involve building or maintaining complex things? E.g. creating a complex dish from simple ingredients.)</label><br />
                <input type="checkbox" name="tendencies3" id="continuity3" value={TendencyType.CONTINUITY} checked={tendenciesList["tendencies3"] & TendencyType.CONTINUITY} onChange={handleTendenciesChange} />
                <label htmlFor="continuity3"><b>Continuity</b> (Does the activity involve building or maintaining things that last long? E.g. improving your health.)</label><br />
                <input type="checkbox" name="tendencies3" id="curiosity3" value={TendencyType.CURIOSITY} checked={tendenciesList["tendencies3"] & TendencyType.CURIOSITY} onChange={handleTendenciesChange} />
                <label htmlFor="curiosity3"><b>Curiosity</b> (Is the activity driven by curiosity? E.g. travel or meeting new people or exploring new ideas.)</label><br />
                <input type="checkbox" name="tendencies3" id="creativity3" value={TendencyType.CREATIVITY} checked={tendenciesList["tendencies3"] & TendencyType.CREATIVITY} onChange={handleTendenciesChange} />
                <label htmlFor="creativity3"><b>Creativity</b> (Will the activity create something new? E.g. a new product or service or idea etc.)</label><br />
                <input type="checkbox" name="tendencies3" id="consciousness3" value={TendencyType.CONSCIOUSNESS} checked={tendenciesList["tendencies3"] & TendencyType.CONSCIOUSNESS} onChange={handleTendenciesChange} />
                <label htmlFor="consciousness3"><b>Consciousness</b> (Does the activity involve a consciousness-enriching experience? E.g. playing great music or writing something meaningful.)</label><br />
                <input type="checkbox" name="tendencies3" id="evolution3" value={TendencyType.EVOLUTION} checked={tendenciesList["tendencies3"] & TendencyType.EVOLUTION} onChange={handleTendenciesChange} />
                <label htmlFor="evolution3"><b>Evolution</b> (Does the activity have the potential to evolve into something better? E.g. starting a new project or relationship.)</label><br />
                <input type="checkbox" name="tendencies3" id="diversity3" value={TendencyType.DIVERSITY} checked={tendenciesList["tendencies3"] & TendencyType.DIVERSITY} onChange={handleTendenciesChange} />
                <label htmlFor="diversity3"><b>Diversity</b> (Will the activity lead to increased diversity in the world or your own thinking? E.g. supporting something new and generally overlooked.)</label><br />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'act2')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 3, 'submit')}>Next</button>
            </div>
          </div>
        )}
        {view === 'submit' && (
          <div className="page-content">
            <h3>6/6. That's it! Now it's time for your prize:</h3>
            <ol className="items-list">
              <li>All the descriptions you have entered during this practice will now be fed to the MindFeed AI and it will create a fun 'memento' based on them.</li>
              <li><a href="/login">Signed in</a> users can edit the memento if they choose to and save it to their profile.</li>
              <li>Please note that the memento is only meant as a fun sharable souvenir. What's really important is doing the practice regularly. Return to MindFeed on a daily basis and you will start to see its benefits over time.</li>
              <li>Also note that MindFeed is only intended to be a quick introduction to these practices. Eventually you may want to take lessons from an expert.</li>
            </ol>
            <div>
            <b>Choose the type of memento and submit:</b><br />
              <input type="radio" className="indented" id="generic" name="generic" value="generic" checked={isGenericMemento(subtype)} onChange={handleSubtypeChange} />
              <label htmlFor="generic">Generic memento</label><br /><br />
              <span className='indented'>(Personalized memento{ !currentUser && <span> - <a href="/login">Sign in</a> to enable</span> }):</span><br/>
              <input type="radio" id="poem" name="subtype" value={MementoSubtype.POEM} checked={subtype === MementoSubtype.POEM} onChange={handleSubtypeChange} disabled={currentUser==null} />
              <label htmlFor="poem" className={ currentUser ? "" : "disabled" }>Poem</label><br />
              <input type="radio" id="monologue" name="subtype" value={MementoSubtype.MONOLOGUE} checked={subtype === MementoSubtype.MONOLOGUE} onChange={handleSubtypeChange} disabled={currentUser==null} />
              <label htmlFor="monologue" className={ currentUser ? "" : "disabled" }>Monologue</label><br />
              <input type="radio" id="scifi" name="subtype" value={MementoSubtype.SCIFI} checked={subtype === MementoSubtype.SCIFI} onChange={handleSubtypeChange} disabled={currentUser==null} />
              <label htmlFor="scifi" className={ currentUser ? "" : "disabled" }>Sci-Fi</label><br />              
              <input type="radio" id="romance" name="subtype" value={MementoSubtype.ROMANCE} checked={subtype === MementoSubtype.ROMANCE} onChange={handleSubtypeChange} disabled={currentUser==null} />
              <label htmlFor="romance" className={ currentUser ? "" : "disabled" }>Romance</label><br />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'act3')}>Back</button>
              <button type="submit" className="app-button">Submit</button>
            </div>
          </div>
        )}
      </form>
      <Modal show={waitingMessage} title="Please Wait" onClose={() => setWaitingMessage('')}>
        <p>{waitingMessage}</p>
      </Modal>
      <Modal show={errorMessage} title="Error" onClose={() => setErrorMessage('')}>
        <p>{errorMessage}</p>
      </Modal>
    </div>
  );
}

export default MorningReflection;
